.masonry {
    display: block;
    position: relative;
}

.js [data-component='masonry'] {
    &:extend(.masonry);

    &.masonry-loading {
        &:after {
            &:extend(.spinner);
            &:extend(.spinner-abs-center);
            top: 100px;
        }
    }

    > li:not([role="presentation"]) {
        overflow: hidden;

        > :first-child {
            opacity: 0;
            transform-origin: 50% 50%;
            transform: none;
        }
    }
}
