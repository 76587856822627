@import "../definitions/mixins";

.on-desktops({
    ::-webkit-scrollbar {
        width: 8px;
    }

    ::-webkit-scrollbar-track {
        background-color: #f0f0f0;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #bbb;
    }
});
