.action {
    background-color: @color-secondary;
    display: block;
    position: relative;
    border-radius: 2px;
    cursor: pointer;
    overflow: hidden;

    &:after {
        content: " ";
        display: block;
        padding-top: 100%;
    }
}

.action-inner {
    position: absolute;
    left: 0; right: 0; top: 0; bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    &:before,
    &:after {
        content: " ";
        display: block;
        position: absolute;
        right: 0; bottom: 0;
    }

    &:before {
        width: 15%;
        height: 15%;
        .stripes(@color-text-default, transparent, "../../img/stripe.png");
    }

    &:after {
        width: 30%;
        height: 16%;
        background-color: @color-secondary;
        transform: rotate(135deg) translateX(-17.5%);
    }
}

.action-icon {
    font-size: 2rem;
    transition: text-shadow .4s ease-out;

    .action:hover & {
        text-shadow: white 20px -5px, white -20px -5px;
    }
}

.actions {
    margin: 0 -1rem;
    padding: 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    > li {
        display: block;
        padding: 0 1rem 1rem;
        width: 25%;

        .on-handhelds({
            width: 50%;
        });
    }
}
