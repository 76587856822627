.stripes(@color, @bg, @fallback, @spacing: 12%) {
    @step-1: (0% + @spacing);
    @step-2: (50% + @spacing);
    background: url(@fallback) repeat @bg;
    background-size: 4px 4px;

    .on-high-res({
        background-image: linear-gradient(-45deg, @color @step-1, transparent @step-1, transparent 50%, @color 50%, @color @step-2, transparent @step-2, transparent);
    });
}

.decoration-striped {
    .stripes(@color-text-default, white, "../../img/stripe.png");
}

.decoration-icon {
    position: absolute;
    width: 0;
    left: 0;
    top: 0; bottom: 0;

    &:after,
    &:before {
        .sg();
        position: absolute;
        right: 0;
        margin-right: .75em;
        color: #eaeaea;
        top: 50%;
    }

    &:after {
        transform: translateY(-50%);
        transform-origin: 50% 50%;
    }

    &.sg-pin:after {
        transform: rotate(45deg) translateY(-22.5%);
        margin-right: .9em;
        transform-origin: 100% 0;
    }

    &.sg-pen {
        &:before {
            content: " ";
            display: block;
            width: .5em;
            bottom: 0;
            height: 3px;
            background-color: currentColor;
            margin-right: 1.7em;
            transform: translateY(.35em)
        }
    }

    .on-small-devices({
        display: none;
    });
}
