// third-party imports
@import "third-party/bootstrap";
@import "third-party/fontawesome";
@import "third-party/google-fonts";
@import "third-party/components";
@import "third-party/photoswipe";
@import "third-party/tagify";

// definitions
@import "definitions/variables";
@import "definitions/mixins";
@import "definitions/animation";
@import "definitions/util";

@import "components/base";
@import "components/decorations";
@import "components/box";
@import "components/util";
@import "components/entity";
@import "components/sidebar";
@import "components/button";
@import "components/content";
@import "components/masonry";
@import "components/icons";
@import "components/publication";
@import "components/nav";
@import "components/avatar";
@import "components/calendar";
@import "components/page";
@import "components/alert";
@import "components/form";
@import "components/editor";
@import "components/dropdown";
@import "components/grid";
@import "components/profile";
@import "components/media";
@import "components/gallery";
@import "components/time";
@import "components/disclaimer";
@import "components/group";
@import "components/carousel";
@import "components/def";
@import "components/event";
@import "components/breadcrumb";
@import "components/drop";
@import "components/map";
@import "components/tabbed";
@import "components/pickii";
@import "components/thread";
@import "components/hamburger";
@import "components/dock";
@import "components/tabbl";
@import "components/action";
@import "components/game";
@import "components/selectable";
@import "components/select";
@import "components/scrollbars";
@import "components/section";
@import "components/numspi";
@import "components/datetime";
@import "components/visible";
@import "components/configurator";
@import "components/switch";
@import "components/transitions";
@import "components/meta";
@import "components/wrap";
@import "components/progress";
@import "components/quote";
@import "components/table";
@import "components/spinner";
@import "components/thumbnail";
@import "components/poll";
@import "components/search";
@import "components/tag";
@import "components/chart";
@import "components/claim";
@import "components/keystroke";
@import "components/conversation";
@import "components/campaign";
@import "components/help";

/*
 * The less-parser has seen his best days and chokes on modern css syntax.
 * Any rules using modern CSS should be defined in this file.
 */
@import (css) "modern.css";
