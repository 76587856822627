.gallery-header {
    padding: 1rem;
    border-bottom: @component-border-width solid rgba(0, 0, 0, 0.1);
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 3.5rem;
    background-color: #f2f2f2;
    position: relative;

    .on-handhelds({ flex-direction: column; });

    h4 {
        margin: 0;
        font-family: @font-family-default;
    }

    small, span {
        display: block;
        line-height: 20px;
        transition: opacity .1s, transform ease-out .1s .1s;
    }

    span {
        transform: translateY(11px)
    }

    small {
        opacity: 0;
    }

    .gallery-editor-uploading & {
        span, small {
            transition: opacity .1s .1s, transform ease-out .1s;
        }

        span {
            transform: none;
        }

        small {
            opacity: 1;
        }
    }
}

.gallery-editor-uploading {

}

.gallery-header-description {
    color: rgba(0, 0, 0, .5);
    flex: 1;

    > :first-child {
        margin-top: 0;
    }

    > :last-child {
        margin-bottom: 0;
    }
}

.gallery-header-actions {
    margin-left: 1rem;

    .btn {
        white-space: nowrap;
    }

    .gallery-header-description-large + & {
        align-self: flex-end;
    }
}

.gallery {
    &:extend(.entity-list all);
    &:extend(.entity-list-3 all);

    a {
        display: block;
    }

    img {
        max-width: none;
        width: 100%;
        display: block;
    }
}

.gallery-image-preview {
    display: block;
}

.gallery-progress {
    position: absolute;
    bottom: -@component-border-width;
    left: 0;
    height: @component-border-width;
    background-color: @brand-success;
    transition: transform .2s;
    transform-origin: 0;
    display: block;
    width: 100%;
}
