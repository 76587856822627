.help-content-image {
    &:extend(.content-image);
    width: 75px;
    color: @color-text-default;
    text-align: center;
}

.help-content-preview {
    &:extend(.content-preview all);
    margin-bottom: 1rem;
}
