.flex-row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > :not(:first-child) {
        margin-left: 1rem;
    }
}

.anchor {
    margin-top: -@nav-height;
    padding-top: @nav-height;
}

.block {
    display: block;
}

.mt-1 {
    margin-top: 0.5rem;
}

.mt-3 {
    margin-top: 1.5rem;
}

.p-0 {
    padding: 0 !important;
}
