.disclaimer {
    background-color: @color-secondary;
    color: #333;
    padding: 1rem 2rem;
    text-align: center;
    font-size: 1rem;;

    * {
        max-width: 48rem;
    }

    :first-child {
        margin-top: 0;
    }

    :last-child {
        margin-bottom: 0;
    }

    [data-component~="time"] {
        font-weight: bold;
    }

    ol, ul, p {
        margin: 0 0 1rem 0;
    }
}

.disclaimer-info {
    background-color: #113A4C;
    color: white;
}

.disclaimer-standalone {
    margin-bottom: 1rem;
}
