.avatar-size(@size) {
    width: @size;
    height: @size;
    max-width: @size;
    max-height: @size;
    min-width: @size;
    min-height: @size;
    font-size: (@size / 2);

    &.avatar-initials {
        &[data-initials="3"] {
            font-size: (@size / 3);
        }

        &[data-initials="4"] {
            font-size: (@size / 4);
        }
    }
}

.avatar {
    &:extend(.avatar-size-64x64);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    vertical-align: middle;
    color: white;
    padding: 0;
    text-decoration: none;
    font-weight: 300;
    line-height: 1;
    cursor: default;
    user-select: none;

    img {
        position: relative;
        display: block;
        max-width: 100%;
        max-height: 100%;
        z-index: 1;
    }

    span {
        pointer-events: none;
    }
}

.avatar-initials {
    background-color: @color-primary;
    text-transform: uppercase;

    a:has(&) {
        text-decoration: none;
    }
}

.avatar-wrap {
    text-decoration: none;
    text-decoration-skip: objects;
}

.avatar-more {
    background-color: rgba(0, 0, 0, .1);
    color: @color-text-default;
    text-align: center;
    position: relative;

    &:before {
        content: "...";
        line-height: 1;
        vertical-align: text-top;
        position: absolute;
        top: 40%;
        left: 0; right: 0;
        transform: translateY(-50%);
        font-weight: bold;
    }
}

.avatar-circle {
    border-radius: 50%;
    overflow: hidden;
}

.avatar-inline {
    display: inline-flex;
}

.avatar,
.avatar-size-64x64 {
    .avatar-size(64px);
}

.avatar-size-48-48 {
    .avatar-size(48px);
}

.avatar-size-32-32 {
    .avatar-size(32px);
}

.avatar-size-24-24 {
    .avatar-size(24px);
}

.avatar-size-16-16 {
    .avatar-size(16px);
}

.avatars {
    margin: 0;
    padding: 0;
    font-size: 0;

    .avatar {
        line-height: 1.5;
    }

    .avatar:not(:last-child),
    > li:not(:last-child) .avatar {
        margin-right: .5em;
    }

    > li {
        display: inline-block;
        vertical-align: top;
    }

    a {
        text-decoration: none;
    }
}

.avatars-nowrap {
    white-space: nowrap;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
}

.avatars-grouped {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0 -.25rem -.5rem -.25rem;
    flex-wrap: wrap;

    .avatar {
        margin: 0 .25rem .5rem .25rem;
    }

    &::after {
        content: ' ';
        flex: auto;
        width: 64px;
    }

    @supports (display: grid) {
        & {
            display: grid;
            // todo: this rule is specific to 64px avatars, maybe there’s something dynamic?
            grid-template-columns: repeat(auto-fill, minmax(64px, 1fr));
            grid-gap: .5rem;
            grid-auto-flow: dense;
            margin: 0;

            .avatar-wrap {
                display: flex;
                align-items: flex-start;
                justify-content: center;
            }

            .avatar {
                margin: 0;
            }
        }
    }
}

.avatar-noedit-inline {
    position: relative;
    display: inline-block;
    text-decoration: none;
    z-index: 10;
}

.avatar-edit-inline {
    &:extend(.avatar-noedit-inline);

    &:after {
        .sg();
        content: @fa-var-pencil;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        width: 65%;
        height: 65%;
        text-align: center;
        display: block;
        background-color: rgba(255, 255, 255, .9);
        color: @color-text-default;
        font-size: 1.5rem;
        opacity: 0;
        transition: opacity .2s;
        line-height: (64px / 100 * 65);
        pointer-events: none;
        z-index: 100;
    }

    &:hover:after,
    &:active:after,
    &:focus:after {
        opacity: 1;
    }
}
