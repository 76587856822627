.chart {

}

.chart-pie {
    width: 3rem;
    height: 3rem;
    overflow: visible !important;
    background-clip: content-box, padding-box;
    position: relative;

    &:hover {
        z-index: 220;
    }
}

.chart-color-danger {
    fill: @brand-danger;
}

.chart-color-success {
    fill: @brand-success;
}

.chart-color-warning {
    fill: @brand-warning;
}

.chart-arc {
    transition: transform .1s ease-out;

    &:hover,
    &:active,
    &:focus {
        transform: scale(1.25);
    }
}
