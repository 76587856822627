@hamburger-speed: .5;
@hamburger-width: 26px;
@hamburger-spacing: 7px;

.hamburger {
    appearance: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: @hamburger-width;
    width: @hamburger-width;
    transition: (@hamburger-speed * 0.3s);
    user-select: none;
    background: transparent;
    outline: none;
    -webkit-tap-highlight-color: transparent;
    color: @color-text-default !important;

    > * {
        display: block;
    }

    &:hover,
    &:focus {
        outline: none;
    }

    &::-moz-focus-inner {
        border: 0;
    }
}

.hamburger-lines {
    @height: 3px;

    display: inline-block;
    height: @height;
    width: @hamburger-width;
    border-radius: 2px;
    transition: (@hamburger-speed * 0.3s);
    background: currentColor;
    position: relative;

    &:before,
    &:after {
        display: inline-block;
        height: @height;
        border-radius: 2px;
        background: currentColor;
        content: ' ';
        position: absolute;
        left: 0;
        width: 100%;
        transition: top (@hamburger-speed *.3s) (@hamburger-speed * .6s) ease, transform (@hamburger-speed * .3s) ease;
        transform-origin: 50% 50%;
    }

    &:before {
        top: @hamburger-spacing;
    }

    &:after {
        top: -@hamburger-spacing;
    }

    input:checked ~ label & {
        transform: scale3d(0.8, 0.8, 0.8);
        background: transparent;

        &:before,
        &:after {
            transition: top (@hamburger-speed * .3s) ease, transform (@hamburger-speed * .3s) (@hamburger-speed * .5s) ease;
            width: @hamburger-width;
            top: 0;
        }

        &:before {
            transform: rotate3d(0, 0, 1, 45deg);
        }

        &:after {
            transform: rotate3d(0, 0, 1, -45deg);
        }
    }
}
