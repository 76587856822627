:root {
    position: relative;

    font-weight: 400;
    font-family: @font-family-default;
    font-size: @font-size-base;
    line-height: @line-height-base;
    color: @color-text-default;

    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -moz-font-feature-settings: "liga" on;

    background: @body-bg;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    @media (max-width: 1199px) {
        font-size: @font-size-small;
    }

    --color-primary: @brand-primary;
    --color-primary-dark: @brand-primary-dark;
    --color-secondary: @brand-secondary;

    --font-default: "Roboto", "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", "Geneva", "Verdana", sans-serif;
    --font-accent: "Roboto Slab", "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", "Geneva", "Verdana", sans-serif;
}

body {
    z-index: 1;
    position: relative;
    overflow-y: scroll;
    padding-top: @nav-height;
    max-width: 100vw;
}

h1, h2, h3, h4, h5 {
    font-family: @font-family-headline;
}

a {
    color: @color-primary;
}

p {
    [data-original-content]:first-child + &,
    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &:empty {
        display: none;
    }
}

audio,
video {
    &::-internal-media-controls-download-button {
        display:none;
    }

    &::-webkit-media-controls-enclosure {
        overflow:hidden;
    }

    &::-webkit-media-controls-panel {
        width: calc(100% + 30px);
    }
}


// iOS "clickable elements" fix for role="button"
//
// Fixes "clickability" issue (and more generally, the firing of events such as focus as well)
// for traditionally non-focusable elements with role="button"
// see https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile
[role="button"] {
    cursor: pointer;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

// Reset fonts for relevant elements
input,
button,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

abbr[title] {
    font-variant: normal;
    border-bottom: none;
}

img[src] {
    position: relative;
    max-width: 100%;
}

// Reset fonts for relevant elements
input,
button,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

textarea {
    resize: vertical;
}

address {
    p {
        margin: 0;
    }
}

ul, ol {
    padding-left: 30px;
}

blockquote {
    border-left: 2px solid rgba(0, 0, 0, .1);
    padding-left: .75rem;
    margin-left: 1rem;

    :only-child {
        margin-top: 0;
        margin-bottom: 0;
    }

    :first-child {
        margin-top: 0;
    }

    :last-child {
        margin-bottom: 0;
    }
}

pre {
    background-color: @gray-lighter;
    border-radius: 2px;
    padding: 1rem;
    color: @color-primary;
}

kbd {
    font-family: 'Roboto Mono', monospace;
}

// force all inputs on iOS devices to a font-size of 16px,
// because otherwise iOS will zoom into the input fields on-focus
@media screen and (pointer: coarse) and (-webkit-min-device-pixel-ratio: 0) {
    select, textarea, input[type="text"], input[type="password"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="url"], input[type="search"], input[type="tel"], input[type="color"] {
        font-size: 16px !important;
    }
}
