.page-footer {
    &:extend(.decoration-striped);
    margin: 4rem 0 0;
    display: flex;
    align-items: stretch;
}

.page-footer-content,
.page-footer-sidebar {
    padding: 1rem 2rem 3rem;
    position: relative;
}

.page-footer-content {
    background: var(--grouprise-logo-backdrop) no-repeat 2rem 2rem rgba(255, 255, 255, .8);
    background-size: 5rem auto;
    padding-left: 9rem;
    min-height: 7rem;
    flex: 1;

    .on-handhelds({
        padding-left: 2rem;
        padding-top: 10rem;
    });
}

.page-footer-text {
    margin-top: 0;
    margin-bottom: 1rem;
}

.page-footer-sidebar {
    flex-basis: 31.5%;

    &:after {
        content: " ";
        display: block;
        background-color: rgba(255, 255, 255, .8);
        position: absolute;
        top: .6rem;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .on-handhelds({
        display: none;
    })
}

.page-prefix {
    padding: 1.5rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 5.25rem;

    .on-handhelds({
        padding: 0;
        height: auto;
        display: block;
        margin: 0 -15px;
    });
}

.page-info {
    display: flex;
    align-items: center;
    text-align: right;
    color: @color-text-extralight;

    .on-handhelds({
        display: none;
    });
}

.page-meta {
    font-size: 0.85em;
}

.page-help {
    font-size: 2em;
    margin-left: .75rem;

    a {
        color: @color-text-extralight;
    }
}
