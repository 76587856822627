@keyframes fade-vertical-auto {
    0%, 100% {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
    }

    10%, 90% {
        opacity: 1;
        transform: none;
    }
}

@keyframes fade-vertical-in {
    from {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
    }

    to {
        opacity: 1;
        transform: none;
    }
}

@keyframes fade-vertical-out {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fade-out {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}
