.keystroke {
    background-color: #f7f7f7;
    border: 1px solid #ccc;
    border-radius: @border-radius-base;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2), 0 0 0 2px #fff inset;
    color: #333;
    display: inline-block;
    font-size: .8em;
    margin: 0 .1em;
    padding: .1em .6em;
    text-shadow: 0 1px 0 #fff;
}
