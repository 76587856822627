.editor-container {
    position: relative;
    overflow: hidden;

    &.editor-container-ready {
        transition: border-color .2s;
        border: @input-border-width solid @input-border;
        border-radius: @border-radius-base;

        &.editor-container-active {
            border-color: @color-primary;
        }

        .control-label {
            display: none;
        }
    }

    .form-group {
        margin: 0;
    }

    + .help-block {
        margin-top: 5px;
    }
}

.editor-dialog {
    width: 400px;
    max-width: 100vw;
    overflow: hidden;
    padding: 1rem;
    background-color: white;
}

.editor-toolbar {
    background-color: white;
    opacity: 1 !important;
    padding: @padding-base-vertical;
    display: flex;
    transition: transform .1s ease-out;
    border: none;
    border-bottom: 1px solid @input-border;
    z-index: 10;
    border-radius: 0;

    a {
        display: flex;
        align-items: center;
        justify-content: center;
        border-color: transparent !important;
        border-radius: @border-radius-base;
        width: 2rem;
        height: 2rem;

        &:hover,
        &:focus,
        &:active {
            background-color: white;
        }
    }

    &.disabled-for-preview a:not(.no-disable) {
        background: inherit;
        opacity: .1;
    }

    .editor-container:not(.editor-container-active) & {
        transform: none !important;
    }

    .sg-editor-hx::after {
        font-family: @font-family-default;
        font-size: .65em;
        vertical-align: text-bottom;
        position: relative;
        top: 2px;
    }

    .sg-editor-h2::after {
        content: '2';
    }
}

.editor-images {
    min-height: 250px;

    .tabbed-content {
        height: 230px;
        overflow: auto;
        overflow-x: hidden;
        background-color: #eaeaea;
        padding: 3px;
        border-radius: @border-radius-base @border-radius-base @border-radius-base 0;
    }

    .tabbed-tabs {
        border-top: 6px solid #eaeaea;
    }

    .tabbed-tab {
        font-size: @font-size-small;
    }

    .btn-toolbar {
        margin: -.5rem 0 .3rem;
    }
}

.editor-images-upload {
    pointer-events: none;

    &:after {
        content: " ";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(255, 255, 255, .75);
        z-index: 500;
    }
}

.CodeMirror {
    border: none;
}
