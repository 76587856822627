.campaign {
    background-color: @color-secondary;
    color: #333;
    padding: 1rem 2rem;
    text-align: center;
    font-size: 1rem;;

    :first-child {
        margin-top: 0;
    }

    :last-child {
        margin-bottom: 0;
    }

    ol, ul, p {
        margin: 0 0 1rem 0;
    }

    progress {
        width: 100%;
    }
}

