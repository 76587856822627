.poll {
    margin: 2rem 0;
    background-color: #f5f5f5;
    border-radius: @border-radius-base;
}

.poll-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    background-color: #f5f5f5;

    .poll:not(.poll-voting):not(.poll-loading) & {
        position: sticky;
        top: @nav-height;
        z-index: 200;
        box-shadow:
            0 12px 13px -9px rgba(88, 88, 88, 0.15),
            0 4px 4px -4px rgba(66, 66, 66, 0.4);
    }

    .btn {
        white-space: nowrap;
    }
}

.poll-header-icon {
    font-size: 1.75rem;
    color: @color-primary;
    margin-right: .75rem;

    + .poll-info {
        margin-right: auto;
    }
}

.poll-header-actions {
    margin-left: 1rem;
}

.poll-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    border-top: @component-border-width solid #eaeaea;

    .content-meta {
        max-width: 360px;

        .on-handhelds({
            max-width: 100%;
        });
    }
}

.poll-editor {
    margin: 2rem 0;

    .tabs-component-tabs {
        &:extend(.poll-header all);
        justify-content: flex-start;
        background: white;
        padding: 0 1rem;
    }

    .tabs-component-panel {
        overflow: visible;
        &:extend(.poll-body all);

        .btn-toolbar {
            &:extend(.poll-footer all);
        }
    }
}

.poll-editor-simple {
    .form-group {
        margin-bottom: 0;
    }
}

.poll-answers {
    margin: 0;
    padding: 0;
    position: relative;

    & > li {
        display: block;
        margin: 0;
        padding: 0;
        border-bottom: 1px solid rgba(0, 0, 0, .05);

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            padding-bottom: 0;
            border-bottom: none;
        }
    }

    p:only-child {
        margin: 0;
    }

    .btn-toolbar {
        padding: 1rem 0;
        position: absolute;
        bottom: 0; left: 0; right: 0;
        z-index: 1;
        background: linear-gradient(transparent, #f9f9f9 80%, #f9f9f9);
    }
}

.poll-answer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .75rem 1rem;
    background-color: #f9f9f9;

    &:not(:last-child) {
        border-bottom: 1px solid #ededed;
    }

    &[draggable="true"] {
        border-bottom: none;
        opacity: .8;
    }

    .poll-answers-draggable & {
        cursor: move;
    }

    .poll-condorcet &::before {
        content: attr(data-index);
        width: 2.5em;
        height: 2.5em;
        border: @component-border-width solid #eaeaea;
        border-radius: 50%;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 1rem;
        position: relative;
    }

    > :first-child {
        flex: 1;
    }

    > :last-child {
        margin-left: 1rem;
    }

    .sg-poll-favorite {
        color: crimson;
    }

    .datetime-row {
        margin-right: 1rem;
        margin-bottom: 0;
    }

    .btn {
        min-height: 2.5em;
        min-width: 2.75em;

        &[disabled] {
            opacity: .25;
            pointer-events: none;
        }
    }
}

.poll-answer-body {
    min-width: 0;
}

.poll-answer-name {
    display: block;
    font-size: 1.15rem;
}

.poll-answer-voters {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0;

    figcaption {
        margin-right: .5em;
    }
}

.poll-actions {
    * + & {
        margin-left: 1rem;
    }
}

[aria-pressed='true'] {
    &.poll-endorse-yes   { color: @brand-success }
    &.poll-endorse-no    { color: @brand-danger }
    &.poll-endorse-maybe { color: @brand-warning }
}

.poll-btn  {
    padding: .5rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    .fa {
        font-size: 1.3rem;
    }
}
