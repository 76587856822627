.calendar {
    width: 100%;

    caption {
        font-family: @font-family-headline;
        font-size: 1.1rem;
        margin-bottom: .2em;
        padding: .5rem 0 .75rem;

        .calendar-month {
            display: flex;

            a {
                flex-basis: 15%;
                text-align: center;
                padding: 0 .2em;
            }

            .calendar-current-month {
                flex-basis: 70%;
            }
        }
    }
}

.calendar-preview,
.calendar-large {
    border-bottom: 2px solid #f0f0f0;
    margin-bottom: 1rem;

    &:last-child {
        border-bottom: none;
        margin-bottom: 0;
    }

    td, th {
        text-align: center;
        padding: .2em;
        width: (100% / 7);
    }

    th {
        color: @color-text-light;
        font-weight: normal;
    }

    td {
        // height behaves like min-height in table cells
        height: 2.65rem;
    }
}

.calendar-large {
    margin-bottom: 4rem;
}

.calendar-day-exclude {
    color: @color-text-extralight;
    opacity: 0.5;
}

.calendar-day-today {
    font-weight: bold;

    > div {
        background: @gray-lighter;
        height: 2.4rem;
        width: 2.4rem;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;

        > span {
            position: relative;
            top: 1px;
        }
    }
}

.calendar-day-has-events {
    td& {
        padding: 0;
    }

    a {
        position: relative;
        display: block;
        padding: .2em;
        z-index: 5;
        font-weight: bold;
        text-decoration: none;
    }
}

.calendar-daynames {
    border-bottom: 2px solid #f0f0f0;
}

.calendar-sheet {
    @size: 12.5em;

    min-width: @size;
    min-height: @size;
    max-width: @size;
    max-height: @size;
    border: .4em solid @color-text-extralight;
    border-radius: 2px;
    color: @color-text-extralight;
    font-family: @font-family-headline;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 1em;
    margin: 0 1rem 0 0;

    .on-small-devices({
        font-size: 0.5rem;
        margin: 0 1rem 1rem 0;
        float: left;
    });

    > * {
        line-height: 1;
    }
}

.calendar-sheet-dayname {
    font-size: 1.5em;
}

.calendar-sheet-month {
    font-size: 2em;
}

.calendar-sheet-daynumber {
    font-size: 5em;
}

.spinner-overlay(~'.calendar');
