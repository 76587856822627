.wrap-nav {
    position: fixed;
    width: 100vw;
    height: @nav-height;
    background-color: white;
    top: 0;
    z-index: 1000;
    .material-shadow(1);

    .nav-double & {
        height: (@nav-height * 2 + 1px);
    }

    .on-mobiles({
        padding: 0;
    });

    > .container {
        height: @nav-height;
    }
}
