.claim {
    display: inline-flex;
    align-items: center;

    i {
        margin-right: 1rem;
    }

    .breadcrumb:not(:empty) + & {
        display: none !important;
    }
}
