@import (css) "../../../node_modules/@yaireo/tagify/dist/tagify.css";

:root {
    --tagify-dd-color-primary: var(--color-primary);
}

.form-control.tagify {
    --tag-bg: #f5f5f5;
    padding-left: 8.6px;
    padding-right: 8.6px;
}

.tagify__tag {
    margin-right: 5px;
}
