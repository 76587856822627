.medias {
    margin: 0;
    padding: 0;

    > li {
        display: block;
        margin-bottom: (@padding-large-vertical * 1.5);

        &.anchor {
            margin-bottom: 0;
            padding-top: calc(@nav-height + @padding-large-vertical * 1.5);
            margin-top: -@nav-height;
        }
    }
}

.media {
    display: flex;

    .on-handhelds({
        flex-direction: row;
    })
}

.media-image {
    min-width: 64px;
    margin-right: @padding-base-horizontal;
}

.media-content {
    flex: 1;
    min-width: 0;
}

.media-body {
    background-color: @gray-lighter;
    padding: @padding-base-vertical @padding-base-horizontal;
    margin-bottom: @padding-base-vertical;
    position: relative;
    min-height: 64px;
    border-radius: @border-radius-base;

    img,
    audio,
    video {
        max-width: 100%;
        margin: 0 auto;
        max-height: 500px;
        border-radius: 2px;
    }

    audio,
    video {
        width: 100%;
    }

    .media-file & {
        display: flex;
        align-items: center;
        padding: @padding-base-horizontal;
        min-height: 0;
    }

    &:before {
        @size: 10px;
        content: " ";
        position: absolute;
        right: 100%;
        margin-right: -1px;
        top: 10px;
        border-left: @size solid transparent;
        border-top: @size solid transparent;
        border-bottom: @size solid transparent;
        border-right: @size solid @gray-lighter;

        .on-handhelds({
            display: none;
        })
    }

    > :first-child {
        margin-top: 0;
    }

    > :last-child {
        margin-bottom: 0;
    }
}

.media-footer,
.media-footer-left,
.media-footer-right {
    display: flex;
    align-items: center;
}

.media-footer {
    color: @color-text-extralight;
    font-size: @font-size-small;
}

.media-footer-left {
    margin-right: auto;

    .btn.has-square-icon {
        @size: 1rem;
        width: @size;
        height: @size;
        margin: 0 .5rem 0 0;
    }
}

.media-footer-right {
    margin-left: auto;
}

.media-footer-separator {
    display: inline-block;
    margin: 0 .35rem;

    &:after {
        content: "·";
    }
}

.media-comment {
    img {
        max-height: 150px;
    }

    .btn {
        margin-bottom: .5rem;
    }

    .media-image {
        .on-handhelds({
            display: none;
        })
    }
}

.media-instruction {
    &:extend(.media-footer);
    display: block;
    margin-top: .25rem;
}

.spinner-overlay(~'.media');
