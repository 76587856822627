.game-container {
    .no-js & {
        display: none;
    }

    .on-handhelds({
        text-align: center;
    });
}

.game {
    width: 100%;
    display: inline-block;
}

.game-snake {
    max-width: 400px;
}
