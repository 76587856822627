.tabbed,
.tabs-component {

}

.tabbed-tabs,
.tabbed-contents,
.tabs-component-tabs,
.tabs-component-panels {
    padding: 0;
    margin: 0;

    > li {
        display: block;
    }
}

.tabbed-tabs,
.tabs-component-tabs {
    display: flex;
    position: relative;
    padding: 0 1rem;

    &::after {
        content: ' ';
        height: @component-border-width;
        position: absolute;
        display: block;
        left: 0;
        bottom: 0;
        width: 100%;
        background-color: @gray-light;
        z-index: 150;
    }
}

.tabbed-tab,
.tabs-component-tab-a {
    &:extend(.btn all);
    background-color: @gray-lighter;
    color: @color-text-ultralight;
    border-radius: @border-radius-base @border-radius-base 0 0;
    border: @component-border-width solid transparent;
    margin-right: .5rem;

    &:hover,
    &:active,
    &:focus {
        color: @color-text-light;
    }

    .tabbed-tab.tabbed-tab-current &,
    .tabs-component-tab.is-active & {
        background-color: #f9f9f9;
        color: @color-text-light;
        z-index: 200;
        border-color: @gray-light;
        border-bottom-color: transparent;
    }
}

.tabbed-content,
.tabs-component-panel {
    display: none;
    overflow-x: hidden;
}

.tabbed-content-current,
.tabs-component-panel:not([aria-hidden]) {
    display: block;
}
