.thumbnail {
    img {
        max-width: 100%;
        max-height: 100%;
    }
}

.thumbnail-square {
    border-radius: 2px;
}

.thumbnail-100 {
    width: 100px;
    height: 100px;
}

.thumbnail-placeholder {
    background-color: #dadada;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 600;

    :lang(de) &:after {
        content: "Kein Bild ausgewählt";
    }
}
