.profiles {
    &:extend(.entity-list all);
    &:extend(.entity-list-2 all);
}

.profile {
    display: block;
    color: @color-text-default;
    text-decoration: none;

    a& {
        padding: 1rem;
        background-color: #f2f2f2;
        border-radius: 2px;
        border: 2px solid transparent;
        transition: border-color .2s;

        &:hover {
            border-color: rgba(0, 0, 0, .2);
        }
    }
}

.profile-short {
    display: flex;
    position: relative;

    .avatar {
        margin-right: @padding-base-horizontal;
    }
}

.profile-about {
    @size: 180px;
    @fadeSize: 30px;
    margin-top: @padding-base-vertical;
    font-size: @font-size-small;
    max-height: @size;
    position: relative;
    overflow: hidden;

    &:after {
        content: " ";
        display: block;
        position: absolute;
        top: (@size - @fadeSize);
        height: @fadeSize;
        width: 100%;
        background: linear-gradient(transparent, #f2f2f2 90%);
    }

    .box-content &:after {
        background: linear-gradient(transparent, white 90%);
    }
}

.profile-name {
    margin: 0 0 -.1rem 0;
    color: @color-text-default;
    line-height: 1.1;
}

.profile-meta {
    &:extend(.content-mute);
    font-weight: normal;
    margin-top: .2rem;
}

.profile-header {
    position: relative;

    &-info {
        &:extend(.decoration-striped);

        display: flex;
        flex-direction: column;
        padding-bottom: .6rem;
        border-radius: 2px 0 0 2px;
        overflow: hidden;

        &-main {
            background-color: white;
            padding: .6rem .8rem;
        }

        &-slug {
            background-color: rgba(255,255,255,.8);
            padding: .4rem .8rem;
        }
    }

    &-title {
        padding-top: calc(20px + .5rem);
        padding-bottom: 1rem;
        margin-bottom: 3rem;

        &:before,
        &:after {
            content: " ";
            display: block;
            position: absolute;
            left: 0; right: 0;
            height: 10px;
        }

        &:before {
            .stripes(@color-text-default, transparent, "../../img/stripe.png");
            top: 0;
        }

        &:after {
            .stripes(white, transparent, "../../img/stripe_white.png");
            background-position: -2px 0;
            top: 10px;
            clear: both;
        }

        .avatar-edit-inline,
        .avatar-noedit-inline {
            margin-right: 1rem;
            border: 2px solid white;
            border-radius: 2px;
            float: right;
        }

        h1 {
            font-size: 1.5rem;
            color: white;
            text-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
            margin: -.05em 0 0;
            line-height: 1.2;
        }

        .on-small-tablets({
            margin-bottom: 0;
        });

        .on-handhelds({
            display: flex;
            margin-bottom: 0;
            margin-top: calc(-64px - 1rem);
            padding: 0 1rem 1rem;

            h1 {
                align-self: flex-end;
            }

            &:before,
            &:after {
                display: none;
            }
        });
    }

    &-data {
        position: absolute;
        top: 0; bottom: 0; right: 0;
        width: 40%;

        .on-small-tablets({
            width: 50%;
        });

        .on-handhelds({
            width: 100%;
            position: relative;
        })
    }

    &-title-image {
        img {
            @base-height: 456px;
            display: block;

            &:before,
            &:after {
                content: " ";
                display: block;
                height: calc(@base-height - 2.15rem);
                width: 100%;
            }

            &:before {
                background-color: @color-secondary;
                height: @base-height;
                position: absolute;
                top: 0;
            }
        }

        .on-handhelds({
            position: relative;
        });

        .btn-toolbar-fixed {
            justify-content: flex-end;
        }
    }

    .disclaimer {
        min-height: 450px;
    }

    .on-handhelds({
        margin: 0 -15px;
    });
}
