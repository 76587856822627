.numspi {
    display: flex;
    align-items: center;
}

.numspi-inner {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
}

.numspi-btn {
    background: none;
    border: none;
    box-shadow: none;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    padding: 1rem;
}

.numspi-btn,
.numspi-input {
    padding: .5rem 1rem;
    text-align: center;
}

.numspi-input {
    flex: 1;
    width: 4rem;
    min-width: 0;
    border: solid @gray-lighter;
    border-width: 0 @input-border-width;
    border-radius: @border-radius-base;
    -moz-appearance:textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

.numspi-ctl {
    border: @input-border-width solid @gray-light;
    border-radius: @border-radius-base;
    position: relative;
    display: flex;
    align-items: stretch;
}

.numspi-label {
    font-weight: normal;
    margin: 0 0 0 .5rem;
}
