.group-header {
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    .group-gallery,
    .group-info {
        width: calc(50% - 1rem);
        position: relative;
        margin: 0;

        .disclaimer {
            position: absolute;
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    .avatar-edit-inline {
        float: right;
    }

    .def {
        clear: both;
    }

    .on-handhelds({
        flex-direction: column;
        margin: 0 -15px;

        .group-gallery {
            height: 190px;
        }

        .group-gallery,
        .group-info {
            width: 100%;
            min-height: 0;
            margin: 0;
        }
    })
}

.group-intro-add .section-container {
    display: none;
}

.group-info {
    &:extend(.box all);
    &:extend(.box-striped all);

    padding-top: 1.2rem;
    padding-bottom: 0.6rem;
    position: relative;
    display: flex;
    flex-direction: column;

    &:before {
        content: " ";
        display: block;
        height: 0.6rem;
        width: 100%;
        position: absolute;
        left: 0;
        right: 0;
        top: 0.6rem;
        background-color: rgba(255, 255, 255, .8);
    }
}

.group-info-main {
    flex: 1;
    position: relative;
}

.group-info-main,
.group-info-slug {
    &:extend(.box-inner all);
    background-color: white;
}

.group-info-slug {
    background-color: rgba(255, 255, 255, .8);
}

.group-title {
    margin-top: 0;
    font-size: 1.5rem;
}

.group-actions {
    align-self: flex-end;
}

.groups {
    &:extend(.entity-list all);
    &:extend(.entity-list-2 all);
}

.group-preview {
    display: block;
    color: @color-text-default;
    text-decoration: none;
    border-radius: 2px;
    overflow: hidden;
    width: 366px;
    max-width: 100%;

    .group-link & {
        background: white;
        max-width: none;
        .material-shadow(3);

        .group-preview-body {
            border-width: 2px 0 0 0;
        }
    }
}

.group-preview-body {
    position: relative;
    font-size: @font-size-small;
    border: 2px solid #f2f2f2;
    padding: 1rem;

    h3 {
        margin: -10px 0 1rem calc(2rem + 72px);
    }

    p {
        margin: 1rem 0 0;
        color: @color-text-light;

        &:first-of-type {
            margin-top: 0;
        }
    }
}

.group-preview-header {
    background-color: white;
    position: relative;

    .group-avatar {
        position: absolute;
        // absolute group-preview-header-size - (avatar size + border times two) / in half
        top: (120px - (64px + 4px * 2) / 2);
        left: 2rem;
        border: 4px solid white;
        background-color: white;
        z-index: 10;
        box-shadow: 0 0 0 2px #f2f2f2;
    }
}

.group-preview-image {
    position: relative;
    height: 120px;
    overflow: hidden;

    img {
        max-width: none;
        display: block;
        height: 100%;
        width: auto;
        position: absolute;
        left: 0; top: 0;
    }
}

.group-preview-stats {
    position: absolute;
    bottom: -2px;
    right: .5rem;
    padding: .3em .6em;
    background-color: white;
    line-height: 1;
    z-index: 10;
    border-radius: 2px 2px 0 0;

    > i + i {
        margin-left: .2em;
    }
}

.group-preview-image-placeholder {
    &:extend(.decoration-striped);

    &:after {
        content: " ";
        display: block;
        position: absolute;
        left: 0; right: 0;
        top: 0; bottom: 0;
        background-color: var(--group-preview-placeholder-color, rgba(255, 255, 255, .6));
    }
}

.group-link {
    transition: transform .2s ease-out;
    z-index: 101;
    margin-top: 5px;
}

.group-media {
    display: flex;
    color: @color-text-default;
    text-decoration: none;
    margin-bottom: 1rem;

    a& {
        padding: 1rem;
        background-color: #f2f2f2;
        border-radius: 2px;
        border: 2px solid transparent;
        transition: border-color .2s;

        &:hover {
            border-color: rgba(0, 0, 0, .2);
        }
    }
}

.group-media-image {
    margin-right: .5rem;
}

.group-media-body {
    font-size: @font-size-small;

    h3 {
        margin: 0 0 .25em 0;
    }

    p {
        margin: 0;
        color: @color-text-light;
    }
}
