.def {

}

.def-icons {
    padding: 0;
    margin: 0;

    dt, dd {
        display: block;
        float: left;
        padding: .2em 0;
        margin: 0;
    }

    dt {
        width: 2rem;
        clear: left;
    }

    dd {
        max-width: calc(100% - 2.5rem);
        max-height: 180px;
        overflow: auto;
    }

    &:after {
        content: " ";
        display: block;
        clear: both;
    }
}

.def-preview {
    padding: .5rem;
    border-radius: 2px;
    background-color: rgba(0,0,0,.05);
    margin-right: 1rem;
    margin-top: 1rem;
    color: @color-text-default;
    max-width: 12.5em;

    dt {
        width: auto;
    }

    .on-small-devices({
        display: none;
    });
}
