.conversation-header {
    &:extend(.flex-row all);
    margin-bottom: 1rem;
}

.conversation-title {
    color: @color-primary;
    font-size: 2rem;
    font-weight: normal;
    margin: 0;
    line-height: 1.3;
    flex: 1;
}
