@dock-speed: .6;

.dock {
    padding: 2rem;
    border-radius: 2px;
    font-size: 1rem;
    overflow: hidden;
    min-width: 250px;
}

.dock-menu {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.dock-fit {
    margin-left: -2rem;
    margin-right: -2rem;
}

.dock-screen(@media-width, @width) {
    @media (min-width: @media-width) {
        max-width: (@width * .75);
        right: calc((100vw - @width) / 2 + 12px);
        left: auto;

        &.dock-left {
            left: calc((100vw - @width) / 2 + 12px);
            right: auto;
        }
    }
}

.dock[data-dropdown] {
    right: 0;
    top: 100%;
    transform: scaleY(0);
    transition: transform (@dock-speed * .4s) ease-out, box-shadow (@dock-speed * .4s) (@dock-speed * .2s) ease-out;
    transform-origin: 50% 0;
    margin-top: 1rem;
    .material-shadow(1);
    max-width: 100vw;

    &.dock-left {
        left: 0;
        right: auto;
    }

    &.dock-absolute {
        position: absolute;
        top: calc(100% + 1rem) !important;
        left: 0 !important;
        right: 0 !important;
        border-top: none !important;
        max-width: 400px;
        z-index: 150;
    }

    &.dock-screen {
        position: fixed;
        top: @nav-height;
        max-height: calc(100vh - @nav-height);
        overscroll-behavior: contain;
        .dock-screen(768px, 750px);
        .dock-screen(992px, 970px);
        .dock-screen(1200px, 1170px);
    }

    &.dock-fullwidth {
        max-width: 100vw;
        left: 0; right: 0;
        margin-top: 0;
        border-top: 1px solid @gray-light;

        .on-handhelds({
            .container {
                padding: 0;
            }
        })
    }

    .dock-inner {
        .dock-header,
        .dock-content {
            transition: opacity (@dock-speed * .4s) (@dock-speed * .4s), transform (@dock-speed * .6s) (@dock-speed * .4s) ease-out;
            opacity: 0;
            transform: translateY(8px);
        }

        .dock-content {
            transition-delay: (@dock-speed * .6s), (@dock-speed * .6s);
        }
    }

    .dropdown-state:checked ~ & {
        transform: scaleY(1);
        .material-shadow(4);

        .dock-inner {
            .dock-header,
            .dock-content {
                opacity: 1;
                transform: none;
            }
        }
    }

    .on-handhelds({
        left: 0;
        margin-top: 0;
        padding-left: 0;
        padding-right: 0;
        padding-top: .75rem;
        position: fixed;
        top: @nav-height;
        border-top: 2px solid #eaeaea;
    });
}

.dock-light {
    background-color: white;
    color: @color-text-default;

    .tabbl-content a {
        color: @color-text-default;
    }
}

.dock-dark {
    background-color: #373F52;

    &,
    .dock-header-link,
    .dock-session,
    .tabbl-content a,
    .profile-name {
        color: white;
    }

    .profile-meta {
        color: rgba(255, 255, 255, 0.4);
    }
}

.dock-meta {
    &:extend(.content-mute);
    margin: 0;
    min-width: 220px;
}

.dock-header {
    max-width: 100vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0 2rem;
    margin-left: auto;
    transition: box-shadow (@dock-speed * .4s) ease-out;

    .dock-content-scrolled & {
        position: relative;
        z-index: 20;
        box-shadow+: 0 -2px 0 white;
        .material-shadow(1);
    }

    :first-child {
        min-width: 0;
    }

    h3 {
        margin: 0;
    }

    .on-handhelds({
        padding-left: 2rem;
        padding-right: 2rem;
    });

    .on-desktops({
        border-bottom: 1px solid rgba(0, 0, 0, .1);
        margin-bottom: 1rem;
    });
}

.dock-header-extension {
    margin-bottom: 1rem;
    border: 1px solid rgba(0, 0, 0, .1);
    border-width: 1px 0 1px 0;
}

.dock-content {
    .on-handhelds({
        // screen height - nav height - dock top border - approx dock header size - dock header margin
        max-height: calc(100vh - (2 * @nav-height) - 2px - 100px - (2rem + .75rem));
        overflow: auto;
    });
}

.dock-content-inner {
    .on-handhelds({
        padding: 0 2rem;
    });
}

.dock-header-link,
.dock-session {
    &:extend(.btn all);
    &:extend(.btn-default all);
    color: @color-text-default;
    white-space: nowrap;
    font-size: .9rem;
    margin-left: 2rem;
    overflow: visible;
    display: flex;
    align-items: center;
    flex: none;

    i {
        font-size: 1.25rem;
        margin-right: .75rem;
    }
}

.dock-trigger {
    display: flex;
    align-items: center;
    padding: .4em .8em;
    background-color: @component-bg-default;
    border-radius: 2px;

    .on-handhelds({
        padding: 0;
        background-color: transparent;
    });
}

.dock-trigger-label {
    margin-left: .8em;

    .on-handhelds({
        display: none;
    });
}

.dock-decoration {
    @height: 2px;
    @spacing: 8px;

    // todo
    display: none;
    position: absolute;
    height: @height;
    width: 60px;
    background-color: currentColor;
    transform: rotate(-45deg);
    opacity: 0;
    transition: opacity (@dock-speed * .3s) (@dock-speed * .4s);

    &::before,
    &::after {
        content: " ";
        position: absolute;
        display: block;
        height: @height;
        background-color: currentColor;
        width: 100%;
        transition: transform (@dock-speed * .6s) (@dock-speed * .4s) ease-out;
    }

    &::before {
        top: @spacing;
        margin-left: 1rem;
        transform: translateX(4rem);
    }

    &::after {
        top: -@spacing;
        margin-left: 2rem;
        transform: translateX(-2rem);
    }

    .dropdown-state:checked ~ .dock[data-dropdown] & {
        opacity: 1;

        &::before,
        &::after {
            transform: none;
        }
    }
}
