.tabbls {
    margin: 0;
    padding: 0;
    display: flex;

    > li {
        display: block;

        &:not(:last-child) {
            margin-right: 2rem;
        }
    }

    .on-small-devices({
        flex-direction: column;

        > li {
            margin: 0 !important;

            &[data-order="low"] {
                order: 3
            }

            &[data-order="medium"] {
                order: 2;
            }

            &[data-order="high"] {
                order: 1;
            }
        }
    });
}

.tabbl {
    width: 300px;
    max-width: 100%;

    .on-small-devices({
        width: 100%;
    });
}

.tabbl-wide {
    width: auto;
}

.tabbl-header {
    position: relative;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    padding-right: 2rem;
    max-width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tabbl-title {
    padding: 1em 1em calc(1em - 4px) 0;
    line-height: 1;
    white-space: nowrap;
    display: inline-block;
    border-bottom: 3px solid @color-primary;
    position: relative;
    bottom: -1px;
}

.tabbl-content {
    padding: 1rem 0;

    .on-small-devices({
        margin-right: 0;
    });
}

.tabbl-list {
    a {
        display: block;
        border-bottom: 1px solid rgba(0, 0, 0, .05);
        border-radius: 2px;
        padding: .6em 0;
        margin-bottom: .25em;
        text-decoration: none;
        cursor: pointer;

        &:last-child {
            border-bottom: none;
        }

        &:hover,
        &:focus,
        &:active {
            text-decoration: underline;
        }
    }
}
