.btn {
    display: inline-block;
    padding: 0.75em 1em;
    border: none;
    border-radius: @border-radius-base;
    min-width: 3.5rem;
    text-align: center;
    text-decoration: none;
    position: relative;
    z-index: 2;
    overflow: hidden;
    cursor: pointer;
    transition: color .2s, background-color .2s, transform .2s;
    vertical-align: middle;
    font-weight: 500;
    margin: 0;
    font-family: @font-family-default;
    user-select: none;
    touch-action: manipulation;
    // FIXME: breaks text only buttons, e.g. file attachment links below contributions
    // min-height: 2.25em;
    font-size: 1rem;

    + & {
        margin-left: 1em;
    }

    &:hover,
    &:focus,
    &:active {
        z-index: 100;
    }

    i:not(:only-child) {
        margin-right: .25em;
    }
}

.btn-nospace {
    margin: 0 !important;
    padding: 0 !important;
}

.btn-novspace {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.btn-backdrop {
    color: white;
    transform-origin: 50% 50%;
    padding: 0;
    min-width: 0;
    transition: transform .2s;
    background-color: transparent;

    &:hover,
    &:focus,
    &:active {
        transform: scale(1.1);
    }
}

.btn-ts {
    overflow: visible;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}

.btn-default {
    background-color: #eaeaea;
    color: @color-text-default;

    &:hover,
    &:focus {
        background-color: #ddd;

        &.btn-addon-ok:after {
            background-color: @color-primary;
        }
    }

    &.btn-addon-ok:after {
        background-color: @color-text-default;
        color: white;
    }
}

// use where you would normally use a default button
// but the size of the button is unusually large
.btn-plane {
    background-color: #f5f5f5;

    &:hover,
    &:focus {
        background-color: #eaeaea;
    }
}

.btn-state {
    &[aria-pressed] {
        position: relative;
        overflow: visible;
        border: @input-border-width solid transparent;

        &[aria-pressed="true"] {
            border-color: @color-primary;

            &::after {
                .sg();
                @size: 1.75em;
                content: @fa-var-check;
                position: absolute;
                right: (@size / -2);
                top: (@size / -2);
                width: @size;
                height: @size;
                border-radius: 50%;
                background-color: @color-primary;
                color: white;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}

// use in buttons or elements that provide an
// aria-pressed or aria-selected attribute to indicate
// if the component is currently active
.btn-radio-state-indicator {
    @size: 1.5em;
    width: @size;
    height: @size;
    background: white;
    display: inline-flex;
    align-self: center;
    justify-content: center;
    margin: 0 auto;
    border-radius: 50%;
    top: .075em;
    font-size: 1.25rem;
    position: relative;
    border: @input-border-width solid @gray-light;


    &::after {
        @i-size: (@size / 5 * 3);
        font-family: 'FontAwesome';
        content: @fa-var-check;
        color: transparent;
        transition: color .2s;
        display: inline-flex;
        align-items: center;

        [aria-pressed='true'] &,
        [aria-selected='true'] & {
            color: @color-primary;
        }
    }
}

.btn-danger {
    &:extend(.btn-default all);

    &,
    &:hover,
    &:focus {
        color: @brand-danger;
    }
}

.btn-icon {
    &:extend(.btn-default all);

    width: 2.2rem;
    padding-left: 0;
    padding-right: 0;
    min-width: 0;

    &,
    &:hover,
    &:focus {
        background-color: transparent;
    }

    &[aria-pressed="true"] {
        color: @color-primary;
    }

    i {
        font-size: 1.1rem;
    }
}

.btn-text {
    background: none;
    border: none;
    box-shadow: none;
    padding: 0;
    margin: 0;
    display: inline-block;
    color: @color-primary;
    font-weight: normal;
    text-decoration: underline;

    &:hover,
    &:active,
    &:focus {
        text-decoration: underline;
    }
}

.btn-text-light {
    color: white;
}

.btn-link {
    background-color: transparent;
    color: @color-primary;

    &:hover,
    &:active,
    &:focus {
        text-decoration: underline;
    }
}

.btn-block {
    display: block;
    width: 100%;
}

.btn-lg {
    font-size: @font-size-large;
}

.btn-sm {
    font-size: @font-size-small;
    padding: 0.5em 0.75em;
    min-width: 1.5rem;
}

.btn-primary {
    background: @color-primary;
    color: white;

    &:hover,
    &:active,
    &:focus {
        background: @color-primary-dark;
    }

    &.btn-addon-ok:after {
        background-color: rgba(0, 0, 0, .2);
        color: white;
    }
}

.btn-dialog {
    background-color: @gray-lightest;
    border-radius: @border-radius-base;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;

    p {
        margin: 0;

        + p {
            margin-top: 1rem;
        }
    }

    .btn {
        flex: 1 0 auto;
        margin-left: 1rem;
    }
}

.btn-toolbar {
    display: flex;
    align-items: center;

    &:empty {
        display: none;
    }

    > * + *:not(.btn-link) {
        margin-left: .75em;
    }

    h3 {
        margin: 0;
    }
}

.btn-toolbar-spread {
    justify-content: space-between;
}

.btn-toolbar-fixed {
    position: absolute;
    padding: 0.625rem 1rem;
    left: 0;
    right: 0;

    &.btn-toolbar-bottom {
        bottom: 0;
    }
}

.btn-toolbar-right {
    justify-content: flex-end;
}

.btn-toolbar-centered {
    justify-content: center;
}

.btn-toolbar-vertical {
    flex-direction: column;
}

.btn-toolbar-label {
    margin-right: .5rem;
}

.btn-group {
    display: flex;
    position: relative;

    .btn {
        margin: 0;
        border-radius: 0;
    }

    > :first-child ~ * {
        margin-left: -@input-border-width;
    }

    > :first-child {
        border-top-left-radius: @border-radius-base;
        border-bottom-left-radius: @border-radius-base;
    }

    > :last-child {
        border-top-right-radius: @border-radius-base;
        border-bottom-right-radius: @border-radius-base;
    }
}

.btn-more {
    .sg();

    &::after {
        content: @fa-var-ellipsis-h;
        transform: translateY(1px);
    }
}

.btn-addon-ok {
    padding-right: 3.25em;

    &:after {
        .sg();
        content: @fa-var-check;
        width: 2.5em;
        text-align: center;
        position: absolute;
        top: 0; bottom: 0; right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        text-indent: @input-border-width;
        transition: background-color .2s, color .1s;
    }
}

.btn-progress {
    padding-left: 2rem;

    &:before {
        content: " ";
        width: 1rem;
        height: 1rem;
        position: absolute;
        display: inline-block;
        border: @input-border-width solid;
        top: 50%;
        margin-left: -1.5rem;
        transform: translateY(-50%);
        border-radius: 50%;
        transform-origin: 50% 50%;
        animation: btn-progress .75s linear infinite;
    }

    &.btn-primary:before {
        border-color: white;
        border-left-color: #2a62ac;
    }
}

@keyframes btn-progress {
    0% {
        transform: translateY(-50%) rotate(0deg);
    }
    100% {
        transform: translateY(-50%) rotate(359deg);
    }
}

.btn-ch {
    @width: 200px;
    @height: 3rem;

    width: @width;
    height: @height;
    border-radius: 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: none;
    color: white;
    right: -.5rem;
    overflow: hidden;
    clip-path: polygon(0 100%, @height 0%, 100% 0, 100% 100%);

    &:after {
        content: ' ';
        position: absolute;
        right: 0; top: 100%;
        background: @color-primary;
        width: 100%;
        height: @width;
        transform: rotate(45deg) translateY(-100%);
        transform-origin: 0 0;
        z-index: 1;
        pointer-events: none;
        transition: background-color .2s;
    }

    &:hover,
    &:active,
    &:focus {
        &:after {
            background: @color-primary-dark;
        }
    }

    .btn-label {
        // recreate the optical effect that the button label is centered
        transform: translateX(14.4px);
    }
}

.btn-label {
    position: relative;
    z-index: 10;
}

.btn-close {
    background: none;
    padding: 0;
    min-width: 0;
    float: right;
    position: relative;
    top: -2px;
    color: rgba(0, 0, 0, .3);
    margin-left: 1em;

    .content-section-header & {
        width: 2em;
        height: 2em;
        border-radius: @border-radius-base;
        background: white;
        top: 0;
    }
}

.btn.is-unresponsive {
    cursor: default;

    &:active,
    &:focus,
    &:hover {
        box-shadow: inherit;
        border-color: inherit;
        background: inherit;
    }
}

.btn.has-state {
    &[data-state="active"] {
        background: @color-secondary;
        color: rgba(0, 0, 0, .6);
    }

    &[data-state="inactive"] {
        background: @component-bg-default;
        color: rgba(0, 0, 0, .1);
    }
}

.btn.has-square-icon {
    @size: var(--btn-square-size, 2.5rem);
    width: @size;
    height: @size;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 0;
    padding: 0;

    i {
        margin: 0;
    }
}

.btn.has-square-icon-sm {
    @size: 1.5rem;
    width: @size;
    height: @size;
}

.btn.has-hover-title {
    overflow: visible;

    .btn-htitle {
        font-size: .75rem;
        font-weight: bold;
        pointer-events: none;

        &::after {
            content: attr(title);
            opacity: 0;
            pointer-events: none;
            background: white;
            position: absolute;
            z-index: 20;
            top: 100%;
            margin-top: .5rem;
            padding: .5rem;
            border-radius: 2px;
            font-weight: normal;
            color: @color-text-default;
            width: 100%;
            min-width: 175px;
            transform: translateY(.25rem);
            transition: transform .1s ease-out, opacity .1s ease-out;
            text-align: left;
            .material-shadow(1);
        }

        &[data-position="left"]::after {
            left: 0;
        }

        &[data-position="right"]::after {
            right: 0;
        }
    }

    &:hover {
        > .btn-htitle:after {
            transform: none;
            opacity: 1;
        }
    }
}

.btn.float-right {
    @size: 2.25rem;
    width: @size;
    height: @size;
    float: right;
    clear: both;
    margin-top: .25rem;
}
