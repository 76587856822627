@font-family-default: var(--font-default);
@font-family-headline: var(--font-accent);

@font-size-large: 1.3rem;
@font-size-base: 1rem;
@font-size-small: .85rem;

@line-height-base: 1.5;
@line-height-large: 2;
@line-height-computed: 1.5;

@padding-small-vertical: .25rem;
@padding-base-vertical: .5rem;
@padding-large-vertical: 1rem;

@padding-small-horizontal: .5rem;
@padding-base-horizontal: 1rem;
@padding-large-horizontal: 2rem;

@navbar-height: 0;

@border-radius-base: 2px;
@border-radius-large: @border-radius-base;
@border-radius-small: @border-radius-base;

@color-text-default: #444F50;
@color-text-light: #555756;
@color-text-extralight: #8b8f8d;
@color-text-ultralight: #bbbbbb;

@color-divider-light: #eaeaea;

@body-bg: white;

@gray-lightest: #f5f5f5;
@gray-lighter: #f2f2f2;
@gray-light: #dadada;

@color-primary: var(--color-primary, @brand-primary);
@color-secondary: var(--color-secondary, @brand-secondary);
@color-primary-dark: var(--color-primary-dark, @brand-primary-dark);

@brand-primary: #2a62ac;
@brand-primary-dark: darken(@brand-primary, 10%);
@brand-secondary: #f6ed69;
@brand-success: #31b893;
@brand-danger: #d66882;
@brand-info: @brand-primary;

@state-success-text: @brand-success;
@state-danger-text: @brand-danger;

@nav-height: 60px;

@component-border-width: 1px;
@component-bg-default: #f2f2f2;
@component-bg-light: white;


@input-border: @gray-light;
@input-border-focus: @brand-primary;
@input-border-width: @component-border-width;

@ruleset-calendar-icon: {
    position: relative;

    &[data-day]::before {
        content: attr(data-day);
        font-family: var(--font-default);
        font-size: .5em;
        font-weight: bold;
        position: absolute;
        left: 50%;
        top: 67%;
        transform: translate(-50%, -50%);
    }
};
