.carousel-container {
    position: relative;
}

.carousel {
    overflow: hidden;
}

.carousel-slides {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: stretch;

    > li {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 100%;
        background-color: #f0f0f0;

        img {
            display: block;
            margin: 0 auto;
            object-fit: contain;
        }
    }
}

.carousel-container,
.carousel,
.carousel-slides {
    height: 100%;
}

.carousel-nav {
    position: absolute;
    top: 0; bottom: 0;
    left: 0; right: 0;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
}

.carousel-btn-wrap {
    @backdrop: rgba(255, 255, 255, 0.3);
    height: 100%;
    background: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    position: relative;
    transition: opacity .2s;
    opacity: 0;

    &:after {
        content: " ";
        display: block;
        position: absolute;
        top: 0; bottom: 0;
        left: 0; right: 0;
        z-index: 1;
        transition: opacity .5s ease-out;
        opacity: 0;
    }

    &:first-child {
        padding-right: 3rem;

        &:after {
            background-image: linear-gradient(to left, transparent 0, @backdrop 100%);
        }
    }

    &:last-child {
        padding-left: 3rem;

        &:after {
            background-image: linear-gradient(to left, @backdrop 0, transparent 100%);
        }
    }

    &:focus,
    &:hover,
    &:active {
        outline: none;

        &:after {
            opacity: 1;
        }
    }

    .carousel-ready & {
        opacity: 1;
    }
}

.carousel-btn {
    background-color: white;
    border-radius: 50%;
    border: 2px solid white;
    color: #333;
    width: 3rem;
    height: 3rem;
    padding: 0;
    margin: 0;
    position: relative;
    transition: background-color .2s, opacity .2s;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    display: flex;
    z-index: 2;
    opacity: .75;

    .carousel-btn-wrap:hover &,
    .carousel-btn-wrap:focus &,
    .carousel-btn-wrap:active & {
        outline: none;
        opacity: 1;
    }

    .carousel-ready & {
        visibility: visible;
    }
}

.carousel-btn-icon {
    .sg();
    font-size: 1.3rem;
    line-height: 1;

    &:before {
        position: relative;
        top: 1px;
    }

    .carousel-btn-prev > &:before {
        left: -2px;
        content: @fa-var-chevron-left;
    }

    .carousel-btn-next > &:before {
        left: 2px;
        content: @fa-var-chevron-right;
    }
}

.carousel-first .carousel-btn-wrap:first-child,
.carousel-last .carousel-btn-wrap:last-child {
    opacity: 0;
    pointer-events: none;
}

.carousel-index {
    display: block;
    position: absolute;
    top: 100%;
    white-space: nowrap;
    padding: 0;
    margin: .75rem 0 0;
    transition: opacity .2s;
    visibility: hidden;
    opacity: 0;

    > li {
        display: inline-block;
        vertical-align: middle;
        margin-right: .2em;
    }

    .carousel-ready & {
        visibility: visible;
        opacity: 1;
    }
}

.carousel-btn-index {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    padding: 0;
    margin: 0;
    border: none;
    background-color: #eaeaea;
    transition: background-color .2s;

    &:hover,
    &:focus,
    &:active,
    &.carousel-btn-index-current {
        outline: none;
        background-color: @color-text-ultralight;
    }
}
