.box {
    padding-top: .6rem;
    margin-bottom: 2rem;
    background-color: white;
}

.box-striped {
    &:extend(.decoration-striped);
}

.box-title {
    font-weight: 400;
    position: relative;
    color: @color-text-light;
    margin-top: 0;
    margin-bottom: 0.3em;

    i {
        margin-right: .5rem;
    }
}

.box-content {
    background-color: white;
    padding: 0.6em;
    border-radius: 2px;

    > :first-child {
        margin-top: 0;
    }

    > :last-child {
        margin-bottom: 0;
    }

    h3 {
        margin-top: 0;
    }

    + * {
        margin-top: .4rem;
    }
}

.box-inner {
    background-color: rgba(255, 255, 255, 0.8);
    padding: .4rem .8rem;
}

.spinner-overlay(~'.box-content');
