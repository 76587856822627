@import "../definitions/mixins";

.breadcrumb {
    background-color: @component-bg-default;
    padding: .6em .8em;
    line-height: 1;
    white-space: nowrap;
    border-radius: 2px;

    &:not(.breadcrumb-visible):empty {
        display: none;
    }

    &.breadcrumb-visible:empty {
        visibility: hidden;
    }

    > * {
        color: @color-text-default;
        text-decoration: none;
        display: inline-block;
    }

    > a {
        &:hover,
        &:focus {
            color: @color-primary;
        }
    }

    .on-handhelds({
        .page-prefix & {
            border-radius: 0;
            display: block;
            padding-top: 1rem;
            padding-bottom: 1rem;
            overflow: hidden;
            max-width: 100vw;
            text-overflow: ellipsis;
        }
    });
}

.breadcrumb-separator {
    display: inline-block;
    padding: 0 .525em 0 .475em;

    &:before {
        content: " / ";
    }
}
