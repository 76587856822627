.dropdown-container {
    position: relative;
}

.dropdown-state {
    display: none;
}

[data-dropdown] {
    position: absolute;
    pointer-events: none;
    visibility: hidden;
    z-index: -1;

    .dropdown-state:checked ~ & {
        z-index: 20;
        visibility: visible;
        pointer-events: all;

        &[data-dropdown="static"] {
            position: static;
        }
    }
}

.dropdown {
    right: 0;
    top: 100%;
    margin-top: @padding-small-vertical;
    display: inline-block;
    background-color: white;
    padding: @padding-small-vertical 0;
    user-select: none;
    white-space: nowrap;
    border-radius: @border-radius-base;
    width: 280px;
    .material-shadow(1);

    opacity: .3;
    transform: translateY(-5px);
    transition: opacity .2s, transform .2s;

    .dropdown-state:checked ~ & {
        transform: translateY(0);
        opacity: 1;
    }

    i {
        margin-right: .3em;
    }

    > li {
        display: block;


        + li {
            margin-left: 0;
            margin-right: 0;
        }

        > a, > label {
            font-weight: normal;
            padding: @padding-base-vertical @padding-base-horizontal;
            display: block;
            text-decoration: none;
            transition: background-color .2s;
            min-width: 140px;
            color: @color-text-default;
            font-family: @font-family-default;
            text-overflow: ellipsis;
            max-width: 100%;
            overflow: hidden;

            &:hover,
            &:active,
            &:focus {
                background-color: @gray-lighter;
            }
        }
    }
}

.dropdown-separator {
    margin: @padding-small-vertical @padding-base-horizontal !important;
    height: 1px;
    background-color: @color-divider-light;
}

.dropdown-header {
    padding: .5rem 1rem;
    color: @color-text-default;
    font-weight: 500;
    margin-top: .5rem;
    font-size: .8rem;
    background-color: #fafafa;
    text-transform: uppercase;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    &:first-child {
        margin-top: 0;
    }
}

.dropdown-small {
    font-size: @font-size-small;
}

.caret {
    .sg();

    &:before {
        content: @fa-var-caret-down;
    }
}
