.search {
    width: 400px;
    max-width: 100%;
}

.search-results {
    margin-top: 1rem;
}

@keyframes search-spin {
    0% {
        transform: translateY(-50%) rotate(0deg);
    }
    100% {
        transform: translateY(-50%) rotate(359deg);
    }
}
