.table {
    width: 100%;

    th, td {
        padding: .3rem .5rem;
        vertical-align: middle;
    }
}

.table-flex {
    &, tbody, thead, tfoot {
        display: block;
    }

    tr {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.table-condensed {
    th, td {
        padding: .15rem .25rem;
    }
}

.table-striped {
    tbody tr:nth-child(even) {
        background-color: rgba(0, 0, 0, 0.03);
    }
}

.table-align-left {
    margin-right: auto;
    text-align: left;
}

.table-align-right {
    margin-left: auto;
    text-align: right;
}

.table img {
    max-height: 3rem;
    display: block;
}

.table-poll {
    .sg-winner {
        color: @color-secondary;
    }

    td {
        text-align: center;

        &:first-child {
            min-width: 150px;
        }

        .form-group {
            padding: .5rem 0;

            > label {
                display: none;
            }

            .choices li {
                &:nth-child(1) label {
                    background-color: hsla(120, 100%, 97%, 1);
                }
                &:nth-child(2) label {
                    background-color: hsla(0, 100%, 97%, 1);
                }
                &:nth-child(3) label {
                    background-color: hsla(60, 100%, 97%, 1);
                }
            }
        }
    }

    thead tr:last-child {
        th, td {
            border-bottom: 2px solid rgba(0, 0, 0, .05);
        }
    }

    tfoot tr:first-child {
        th, td {
            border-top: 2px solid rgba(0, 0, 0, .05);
        }
    }
}

.table-column-highlight {
    background-color: rgba(0, 0, 0, .05);
}
